<template>
  <div>
    <div v-if="this.$route.name === 'CreditInvoice'">
      <CreditInvoice />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import CreditInvoice from "@/views/companyApp/creditInvoice/CreditInvoice.vue";

export default {
  name: 'CreditInvoiceParent',
  components: {
    CreditInvoice
  }
}
</script>
